@import '../../_settings/_variables.scss';

.searching-header-homepage {
    padding: 18px;
    width: 100%;
    height: 30px;
    margin-left: 40px;
    margin-right: 12px;
}

.searching-button-homepage {
    padding-right: 5px;
}