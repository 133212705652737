@import "../_settings/_variables.scss";

.login-page {
    background-color: whitesmoke;
    padding: 30px;
    border: 2px solid;
    border-radius: 15px;
    width: 400px;

    .remember {
        display: flex;
        justify-content: space-between;

        .remember-password {
            margin-top: 6px;
        }

        .forgot-password {
            cursor: pointer;
            color: #1e90ff;
        }
    }

    .buttons {
        .button-login {
            width: 100%;
        }

    }

    .register-now {
        cursor: pointer;
        color: #1e90ff;

    }
}
