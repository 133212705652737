@import './_variables.scss';

html,
body {
  color: var(--color-type-body);
  font-family: var(--base-font-family);
  font-weight: 300;
  line-height: var(--base-font-lineheight);
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.list-view {
  table {
    table-layout: fixed;
  }

  td {
    word-break: break-all;
  }

  .edit {
    width: 88px;
    text-align: center;
  }

  .delete {
    width: 50px;
    text-align: center;
  }

  .stt {
    min-width: 82px;
  }

  .kich-hoat {
    min-width: 82px;
    text-align: center;
  }

  .date {
    min-width: 92px;
    text-align: center;
  }
}
