@import "../_settings/_variables.scss";

.form-user-reviews {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    margin-top: 20px;
    margin-left: 8px;
    margin-right: 12px;
}

.radio-rating {
    input[type=radio] {
        display: none;
    }
}

.star {
    cursor: pointer;
}