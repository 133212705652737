@import "../_settings/_variables.scss";

.input-item-export {
    margin-bottom: 10px;

    .bid-status-export {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        div {
            display: flex;
            align-items: center;
            margin-top: 1px;

            .bid-status-value-export {
                margin-right: 10px;

                .form-check-label {
                    margin-top: 4px;
                    margin-left: 4px;
                }
            }
        }
    }

    .button-bid-status-export {
        display: flex;
        align-items: center;

        .button-export {
            margin-top: 24px;
        }
    }
}

.value-export {
    text-align: center;
}

.label-export {
    text-align: center;
}

.empty-export-item {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-style: italic;
    width: 100%;
    color: rgb(173, 172, 172);
}