@import "../_settings/_variables.scss";

.box-file-upload {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 4px;
    margin-top: 4px;

    .icon-file-upload {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    label {
        cursor: pointer;
    }
}

.button-refresh-item-detail {
    margin-right: 12px;
}