@import "../_settings/_variables.scss";

.forgot-password-page {
    background-color: whitesmoke;
    padding: 30px;
    border: 2px solid;
    border-radius: 15px;
    width: 400px;

    .button-forgot-password {
        margin-top: 10px;
        width: 100%;
    }
}