@import "../_settings/_variables.scss";

.place-bid-bdpage {
    margin-top: 12px;
    margin-right: 12px;
    margin-left: 12px;

    .place-bid-input-bdpage {
        width: 100%;
        margin-bottom: 8px;
    }

    .place-bid-btn-bdpage {
        width: 100%;
    }
}