@import "../_settings/_variables.scss";

.title-category-heading {
    padding: 10px 10px 0 6px;
}

.table-category {
    margin-left: 10px;
}

.description-text {
    width: 1000px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-action {
    margin-right: 10px;
}

.button-save-edit-category {
    margin-top: 8px;
}

.button-refresh-category {
    margin-right: 12px;
}

/* Mobile: width < 740px */

/* Tablet: width >= 740px and with < 1024px */
@media only screen and (max-width: 1023px) {
    .description-text {
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}