@import "../_settings/_variables.scss";

.banner {
    background-color: white;
    text-align: center;
    margin: 20px;

    .banner-heading {
        padding: 20px;
    }
}

.image-horizontal {
    height: 450px;
    width: 100%;
    margin-left: 0px;
    overflow: hidden;

    .carousel {
        height: 100%;

        .carousel-inner {
            height: 100%;

            .carousel-item {
                height: 100%;

                .label-carousel-category-menu {
                    width: 8%;
                    height: 8%;
                    position: absolute;
                    background-color: whitesmoke;
                    border-radius: 10px;
                    padding: 0px 6px;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    left: 36px;
                    cursor: pointer;
                    top: 84%;
                }

                .carouselImage {
                    width: 100%;
                }
            }
        }

        .carousel-control-prev {
            width: 5%;
        }

        .carousel-control-next {
            width: 5%;
        }
    }
}

.status-auctions {
    background-color: #fff;
    border: 1px solid #ccc;
    margin: 10px;

    .view-item-homepage {
        display: flex;
        flex-wrap: wrap;
    }
}

.hapenning-auction-card,
.upcoming-auction-card {

    display: flex;
    flex-direction: row;
    align-items: center;

    .fullname-buyer {
        color: #18A7FF;
    }

    .name-item {
        text-decoration: none;
        color: #000;
        width: 460px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
    }

    .description-item {
        width: 460px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-text {
        margin-bottom: 0px;
    }

    .start-date-item {
        color: #FF9900;
    }

    .end-date-item {
        color: #FF9900;
    }

    .productImage {
        margin-left: 10px;
        width: 100px;
        height: 100px;
    }

}

.status-auctions {
    .status-auctions-title {
        margin-left: 8px;
    }
}

@media only screen and (max-width:739px) {
    .status-auctions {
        padding: 8px;
    }

    .image-horizontal {
        display: none;
    }
}