:root {
  /*
  * Colors
  */
  --brand: #2995c0;
  --grey-1: #474747;
  --grey-2: #919191;
  --grey-3: #dadada;
  --grey-4: #ededed;
  --color-type-body: var(--grey-1);
  --success: #2a7e2e;
  --atenttion: #2196f3;
  --wait: #fff8dd;
  --error: #d92525;

  /*
  * Fonts
  */
  --base-font-family: sans-serif;
  --headers-font-family: serif;
  --base-font-lineheight: 1.5em;
}

/*
* Colors
*/
$brand-1: lightblue;
$brand-2: #f0f8ff;
$brand-3: #fbecd0;
$brand-4: #ff7f50;

/*
* Fonts
*/

/*
* Image Size
*/
$image-button-width: 22px;
$image-button-height: 22px;
$image-toast-width: 30px;
$image-toast-height: 30px;
$alert-icon-width: 30px;
$alert-icon-height: 30px;
