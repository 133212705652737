@import "../_settings/_variables.scss";

#popover-basic {
    width: 400px;

    .header-popover-history {
        text-align: center;
        font-weight: bold;
    }
}

.image-item-bid-bdpage {
    width: 100%;
    padding: 4px;
    border-radius: 10px;
}

.item_name-bdpage {
    display: flex;
    justify-content: space-between;

    .icon-edit-bdpage {
        width: 32px;
        height: 32px;
    }
}

.item-description-bdpage {
    height: 104px;
}

.bid-information-card-bdpage {
    padding: 12px;
    background-color: #ededed;

    .box-top-bids {
        text-align: center;

        .label-top-bids {
            border: 1px solid;
            border-radius: 6px;
            background-color: rgba(127, 255, 212, 0.699);
            cursor: pointer;
        }
    }

    .singleline {
        margin-top: 0;
        margin-bottom: 0;
    }

    .label-card {
        font-size: 16px;
    }

    .content-card-bdpage {
        display: flex;
        align-items: center;

        .Upcoming {
            color: whitesmoke;
            background-color: orange;
            padding: 4px 8px;
            border-radius: 20px;
            margin-bottom: 4px;
        }

        .Happening {
            color: whitesmoke;
            background-color: green;
            padding: 4px 8px;
            border-radius: 20px;
            margin-bottom: 4px;
        }

        .Ended {
            color: whitesmoke;
            background-color: rgb(201, 28, 28);
            padding: 4px 8px;
            border-radius: 20px;
            margin-bottom: 4px;
        }
    }

    .time {
        color: orange;
        font-size: 16px;
    }

    .minPrice {
        font-size: 24px;
        font-weight: bold;
    }

    .currentPrice {
        font-size: 24px;
        font-weight: bold;
    }
}

/* Mobile: width < 740px */
@media only screen and (max-width:739px) {
    .item-description-bdpage {
        width: 280px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* Tablet: width >= 740px and with < 1024px */
@media only screen and (max-width: 1023px) {}