@import '../../_settings/_variables.scss';

.header-container {
  justify-content: space-between;

  .header-sreachbox {
    display: flex;
    justify-content: center;
    align-items: center;

    .header-logo {
      margin-left: 40px;
      width: 80px;
      height: 80px;
      cursor: pointer;
    }
  }
}