@import '../../_settings/_variables.scss';

.menu-information {
  display: flex;
  align-items: center;
  justify-content: center;

  .image-logout {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;
    padding: 12px;
    border: none;
    font-weight: 500;
  }

  .fullname-header {
    font-size: 20px;
    text-align: center;
  }
}