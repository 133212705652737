@import "../_settings/_variables.scss";

.list-categories {
    margin-top: 104px;
    width: 200px;

    .header-list-group-category {
        display: flex;
        justify-content: space-between;

        .iconCategoryList {
            width: 28px;
            height: 28px;
        }
    }
}

.view-item-detail {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 348px);
}

.title-category-page {
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    .category-name {
        padding-left: 0px;
        width: 600px;
        font-weight: 500;
    }

    .category-description {
        width: 1600px;
        text-align: left;
    }
}

.sort-by-category-page {
    display: flex;
    margin-top: 10px;

    .selected-category {
        margin-left: 20px;
    }
}

.pagination-category-browser {
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}