@import "../_settings/_variables.scss";

.nav-my-bid {
    border: 1px solid #ccc;

    .nav-link {
        color: black;
        font-size: 16px;
    }

    .nav-link:hover {
        color: #ff7b00;
        border-bottom: 1px solid #ff7b00;
    }
}

.my-bid-items {
    display: flex;
    flex-wrap: wrap;
}

.loading-page-check {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 50px;

    .animate-pulse {
        animation: slide 1s infinite alternate;
    }

    @keyframes slide {
        0% {
            transform: translateY(0);
            opacity: 1;
        }

        100% {
            transform: translateY(-10px);
            opacity: 0.5;
        }
    }
}