@import "../_settings/_variables.scss";

.happening-item-category-card {
    background-color: #EDEDED;
    margin: 24px;
    width: 426px;

    .left-side-card {
        margin-bottom: 20px;

        .tag-status-happening {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            top: -10px;
            right: -10px;

            .ribbon__content {
                left: -25px;
                top: 30px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                display: block;
                width: 225px;
                padding: 10px 0;
                background-color: rgba(0, 128, 0, 0.877);
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
                color: #fff;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
                text-transform: uppercase;
                text-align: center;
                border: 2px dotted #fff;
                outline: 5px solid #9cf579;
            }
        }

        .tag-status-upcoming {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            top: -10px;
            right: -10px;

            .ribbon__content {
                left: -25px;
                top: 30px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                display: block;
                width: 225px;
                padding: 10px 0;
                background-color: rgba(255, 166, 0, 0.877);
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
                color: #fff;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
                text-transform: uppercase;
                text-align: center;
                border: 2px dotted #fff;
                outline: 5px solid #f5cd79;
            }
        }

        .tag-status-ending {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            top: -10px;
            right: -10px;

            .ribbon__content {
                left: -25px;
                top: 30px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                display: block;
                width: 225px;
                padding: 10px 0;
                background-color: rgba(184, 30, 30, 0.877);
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
                color: #fff;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
                text-transform: uppercase;
                text-align: center;
                border: 2px dotted #fff;
                outline: 5px solid #e05a5a;
            }
        }
    }


    .right-side-card {
        padding: 0px;



        .description-item-category-page {
            margin-bottom: 0px;
        }

        .time-item-category-page {
            margin-top: -18px;
            display: flex;

            .date-item-category-page {
                margin-top: 6px;
                margin-left: 4px;
            }
        }

        .bid-item-category-page {
            margin-top: -12px;
            display: flex;

            .bid-item-category-page {
                margin-top: 6px;
                margin-left: 4px;
            }
        }
    }

    .btn-view-auction {
        margin-bottom: 10px;
        text-align: end;
    }
}

.item-detail-no-data {
    width: 100%;
    margin: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    color: rgba(180, 177, 177, 0.74);
}

.productImageCategoryPage {
    width: 400px;
    height: 300px;
    margin-top: 12px;
    background-color: white;
}

.fullname-buyer-category-page {
    height: 70px;
    color: #18A7FF;
}

.name-item-category-page {
    height: 50px;
    font-weight: bold;
    text-transform: uppercase;
}

/* Mobile: width < 740px */
@media only screen and (max-width:739px) {
    .happening-item-category-card {
        width: 94%;
        margin-left: 10px;
    }

    .productImageCategoryPage {
        width: 100%;
        height: 240px;
        margin-top: 12px;
        background-color: white;
    }

    .fullname-buyer-category-page {
        width: 200px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .name-item-category-page {
        width: 200px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* Tablet: width >= 740px and with < 1024px */
@media only screen and (max-width: 1023px) {}