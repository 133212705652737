@import '../../_settings/_variables.scss';

.navbar-menu {
  display: flex;

  .button-add-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: none;
    background-color: #fff;
    width: 100px;
  }

  .navbar-library {
    .navbar-nav {
      font-size: 14px;
      margin-left: 4px;

      .header-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .nav-link {
        font-weight: bold;
        margin: 6px;
        background-color: #fff;
        white-space: nowrap;
        padding: 6px 10px;
        text-decoration: unset;
      }

      .image-children {
        margin-right: 4px;
        margin-bottom: 2px;
        width: $image-button-width;
        height: $image-button-height;
      }

      .span-test {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: sans-serif;
        font-size: 16px;
        font-weight: 500;

        .notification-label-menu {
          position: relative;
          right: -4px;
          top: 17px;
          color: white;
          background-color: red;
          border-radius: 50%;
          padding: 0px 6px;
          font-size: 12px;
          margin-top: -30px;
        }
      }
    }
  }
}