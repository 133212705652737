@import "../_settings/_variables.scss";

.register {
  padding: 30px;
  border: 2px solid;
  border-radius: 15px;
  width: 400px;
  background-color: whitesmoke;

  .buttons {
    text-align: end;
  }

  .log-in {
    cursor: pointer;
    color: #1e90ff;

  }
}